import React from 'react';
import { Link } from 'react-router-dom';
import companyLogo from '../assets/images/image-removebg-preview(1).png'; // Ajuste o caminho da logo conforme necessário.

const NetworkError = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-center">
      <img src={companyLogo} alt="Company Logo" className="w-32 h-32 mb-6" />
      <h1 className="text-2xl font-bold text-red-600 mb-4">Erro de Conexão</h1>
      <p className="text-gray-700 mb-6">
        Estamos com um erro em nossos serviços. Não se preocupe, nosso time já está cuidando para resolver isso da maneira mais rápida possível! <br></br> Tente fazer novamente em poucos minutos
      </p>
      <Link
        to="/auth/login"
        className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600"
      >
        Ir para o Login
      </Link>
    </div>
  );
};

export default NetworkError;
