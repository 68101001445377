// components/GenericAssetIcon.js
import React from 'react';

const GenericAssetIcon = ({ className = "w-full h-40 object-cover rounded-lg mb-4 text-gray-400" }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 64 64"
    stroke="currentColor"
  >
    <rect
      x="8"
      y="8"
      width="48"
      height="48"
      rx="6"
      ry="6"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
    />
    <rect
      x="16"
      y="20"
      width="32"
      height="16"
      rx="2"
      ry="2"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
    />
    <circle
      cx="20"
      cy="44"
      r="2"
      fill="currentColor"
    />
    <circle
      cx="44"
      cy="44"
      r="2"
      fill="currentColor"
    />
    <line
      x1="24"
      y1="44"
      x2="40"
      y2="44"
      strokeWidth="2"
      stroke="currentColor"
    />
  </svg>
);

export default GenericAssetIcon;
