import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';

const VerifyAccount = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    const token = searchParams.get('token');

    if (!token) {
      setStatus('error');
      return;
    }

    const verifyEmail = async () => {
      try {
        const response = await axios.get(`https://api.pinnsystem.com/api/verify-email?token=${token}`);
        if (response.status === 200) {
          setStatus('success');
          setTimeout(() => {
            navigate('/auth/login');
          }, 5000);
        }
      } catch (error) {
        setStatus('error');
      }
    };

    verifyEmail();
  }, [navigate, searchParams]);

  const renderContent = () => {
    switch (status) {
      case 'loading':
        return <p className="text-gray-600">Verificando seu e-mail...</p>;
      case 'success':
        return (
          <>
            <h1 className="text-2xl font-semibold text-gray-800 mb-4">Conta Verificada com Sucesso!</h1>
            <p className="text-gray-600 mb-6">
              Seu e-mail foi verificado com sucesso. Você será redirecionado para a página de login em breve.
            </p>
            <button
              onClick={() => navigate('/auth/login')}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
            >
              Ir para Login Agora
            </button>
          </>
        );
      case 'error':
        return (
          <>
            <h1 className="text-2xl font-semibold text-red-600 mb-4">Erro na Verificação</h1>
            <p className="text-gray-600 mb-6">
              O token de verificação é inválido ou expirou. Por favor, tente novamente.
            </p>
            <button
              onClick={() => navigate('/auth/login')}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2"
            >
              Voltar para Login
            </button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-6">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md text-center">
        {renderContent()}
      </div>
    </div>
  );
};

export default VerifyAccount;