import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns';


function InventoryLicense_keyPage() {
    const [assets, setAssets] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        license_key: '',
        version: '',
        status: '',
        tag: '',
        acquisition_date: '',
        expiration_date: ''
    });

    useEffect(() => {
        document.title = `Licenças - PinnSystem`;
        async function fetchAssets() {
            try {
                const response = await axios.get('https://api.pinnsystem.com/api/license/view', {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setAssets(response.data);
            } catch (error) {
                console.error('Erro ao buscar licenças:', error);
            } finally {
                setIsLoading(false);
            }
        }

        fetchAssets();
    }, []);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://api.pinnsystem.com/api/license/', formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            console.log('Nova licença criada:', response.data);
            setShowModal(false);
            setFormData({ name: '', license_key: '', version: '', status: '', tag: '', acquisition_date: '', expiration_date: '' });

            // Adicionar a nova licença ao estado assets para não precisar refazer a consulta
            setAssets((prevAssets) => [...prevAssets, response.data]);
        } catch (error) {
            console.error('Erro ao criar licença:', error);
        }
    };

    const filteredAssets = assets.filter((asset) =>
        asset.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        asset.license_key.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="min-h-screen bg-gray-50 p-8">
            <div className="flex items-center space-x-2 text-gray-500 text-sm">
                <span className="text-blue-500">Licenças</span>
                <span>/</span>
                <span className="font-semibold text-gray-900">Inventário</span>
            </div>
            <h1 className="mt-4 text-2xl font-semibold text-gray-800">Todas as licenças cadastradas</h1>
            <div className="flex justify-between mt-6">
                <div className="flex items-center space-x-4">
                    <input
                        type="text"
                        placeholder="Procurar por licenças"
                        className="px-4 py-2 border border-gray-300 rounded-md w-full max-w-md text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded-md"
                    onClick={() => setShowModal(true)}
                >
                    Nova Licença
                </button>
            </div>

            {showModal && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-4 rounded-lg shadow-lg max-w-sm w-full z-10">
            <h2 className="text-xl font-bold mb-4">Criar Nova Licença</h2>
            <form onSubmit={handleFormSubmit}>
                <div className="mb-3">
                    <label className="block text-gray-700 text-sm">Nome da Licença</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleFormChange}
                        className="w-full px-3 py-2 border rounded-md text-sm"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="block text-gray-700 text-sm">Licença</label>
                    <input
                        type="text"
                        name="license_key"
                        value={formData.license_key}
                        onChange={handleFormChange}
                        className="w-full px-3 py-2 border rounded-md text-sm"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="block text-gray-700 text-sm">Versão</label>
                    <input
                        type="text"
                        name="version"
                        value={formData.version}
                        onChange={handleFormChange}
                        className="w-full px-3 py-2 border rounded-md text-sm"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="block text-gray-700 text-sm">Status</label>
                    <input
                        type="text"
                        name="status"
                        value={formData.status}
                        onChange={handleFormChange}
                        className="w-full px-3 py-2 border rounded-md text-sm"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="block text-gray-700 text-sm">Tag</label>
                    <input
                        type="text"
                        name="tag"
                        value={formData.tag}
                        onChange={handleFormChange}
                        className="w-full px-3 py-2 border rounded-md text-sm"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="block text-gray-700 text-sm">Data de aquisição</label>
                    <input
                        type="date"
                        name="acquisition_date"
                        value={formData.acquisition_date}
                        onChange={handleFormChange}
                        className="w-full px-3 py-2 border rounded-md text-sm"
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="block text-gray-700 text-sm">Data de expiração</label>
                    <input
                        type="date"
                        name="expiration_date"
                        value={formData.expiration_date}
                        onChange={handleFormChange}
                        className="w-full px-3 py-2 border rounded-md text-sm"
                        required
                    />
                </div>
                <div className="flex justify-end space-x-2 mt-4">
                    <button
                        type="button"
                        className="px-4 py-2 bg-gray-300 rounded-md text-sm"
                        onClick={() => setShowModal(false)}
                    >
                        Cancelar
                    </button>
                    <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded-md text-sm">
                        Salvar
                    </button>
                </div>
            </form>
        </div>
    </div>
)}


            {filteredAssets.length > 0 ? (
                <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filteredAssets.map((asset) => (
                        <div key={asset.license_key} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105">
                            {/* Ícone de Licença */}
                            <div className="flex items-center justify-center mb-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-12 h-12 text-blue-500"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M17 12h2a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-2m-4 0h-4m0 0H7a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h4m0 0h4V4a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v4"
                                    />
                                </svg>
                            </div>

                            {/* Título da Licença */}
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">{asset.name}</h3>

                            {/* Informações da Licença */}
                            <div className="space-y-2">
                            <p className="text-gray-600">
  <strong>Expiração:</strong> {format(new Date(asset.expiration_date), 'dd/MM/yyyy')}
</p>

                                <p className="text-gray-600"><strong>Versão:</strong> {asset.version}</p>
                                <p className="text-gray-600"><strong>Status:</strong> {asset.status}</p>
                            </div>

                            {/* Botão de Ação */}
                            <div className="mt-4">
                                <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-300 w-full">
                                    Ver Detalhes
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center mt-20 text-center">
                    <div className="w-28 h-36 bg-blue-100 rounded-md flex flex-col items-center justify-center relative shadow-md">
                        <div className="w-16 h-20 bg-white rounded-sm border border-blue-300 shadow-sm flex flex-col items-center p-2">
                            <div className="w-10 h-1 bg-gray-400 rounded-full mb-2"></div>
                            <div className="w-full h-1 bg-gray-300 rounded-full mb-1"></div>
                            <div className="w-3/4 h-1 bg-gray-300 rounded-full mb-1"></div>
                            <div className="w-5/6 h-1 bg-gray-300 rounded-full mb-1"></div>
                            <div className="w-2/3 h-1 bg-gray-300 rounded-full"></div>
                        </div>
                        <div className="absolute bottom-1 w-10 h-1 bg-blue-400 rounded-full"></div>
                        <div className="absolute bottom-1 right-3 w-3 h-3 bg-blue-300 rounded-full"></div>
                    </div>
                    <p className="mt-6 text-lg font-semibold text-gray-700">Nenhuma Licença Cadastrada</p>
                    <p className="mt-2 text-gray-500">O que está esperando? Crie sua primeira licença agora mesmo!</p>
                </div>
            )}
        </div>
    );
}

export default InventoryLicense_keyPage;
