import React, { useEffect, useState } from "react";
import axios from "axios";

const AccountDetails = () => {
  const [userDetails, setUserDetails] = useState({
    username: "",
    email: "",
    avatar: "",
  });
  const [newUsername, setNewUsername] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);

  // Fetch user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get("https://api.pinnsystem.com/api/user/profile/", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Enviar token
          },
        });
        setUserDetails(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, []);

  // Update username
  const handleUpdateUsername = async () => {
    try {
      const response = await axios.put(
        "https://api.pinnsystem.com/api/update_username/",
        { new_username: newUsername },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      alert(response.data.message);
    } catch (error) {
      console.error("Error updating username:", error);
    }
  };

  // Upload profile picture
  const handleUploadProfilePicture = async () => {
    const formData = new FormData();
    formData.append("file", profilePicture);

    try {
      const response = await axios.post("https://api.pinnsystem.com/api/upload_profile_picture/", formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Profile picture updated successfully");
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-50 to-blue-100 py-10 px-4 font-roboto">
      <div className="max-w-4xl mx-auto bg-white shadow-xl rounded-xl overflow-hidden">
        <div className="bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-4">
          <h2 className="text-2xl font-bold">Detalhes da Conta</h2>
          <p className="text-sm opacity-80">Gerencie suas informações pessoais</p>
        </div>

        <div className="p-8 space-y-8">
          {/* My Details */}
          <section>
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Meus detalhes</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Nome de usuário</label>
                <input
                  type="text"
                  value={newUsername}
                  onChange={(e) => setNewUsername(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
                <button
                  onClick={handleUpdateUsername}
                  className="mt-4 px-4 py-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-md shadow hover:from-blue-600 hover:to-blue-700"
                >
                  Atualizar Nome
                </button>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">E-mail</label>
                <input
                  type="text"
                  value={userDetails.email}
                  disabled
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm bg-gray-100"
                />
              </div>
            </div>
          </section>

          {/* Profile Picture */}
          <section>
            <h3 className="text-lg font-semibold text-gray-800 mb-4">Foto de perfil</h3>
            <input
              type="file"
              onChange={(e) => setProfilePicture(e.target.files[0])}
              className="block w-full border border-gray-300 rounded-md shadow-sm"
            />
            <button
              onClick={handleUploadProfilePicture}
              className="mt-4 px-4 py-2 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-md shadow hover:from-blue-600 hover:to-blue-700"
            >
              Atualizar Foto
            </button>
          </section>
        </div>
      </div>

      {/* Footer Section */}
      <div className="mt-10 text-center text-sm text-gray-500">
        Para deletar a conta, envie mensagem para <span className="text-gray-700 font-medium">cancel-account@pinnsystem.com</span>.
      </div>
    </div>
  );
};

export default AccountDetails;
