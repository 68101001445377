import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BackgroundImage from '../../assets/images/background_for_dezember.png';
import Logo from '../../assets/images/pinn.png';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function LoginPage() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    useEffect(() => {
        document.title = 'Login - PinnSystem';
    }, []);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const handleMouseDown = () => {
        setIsPasswordVisible(true);
    };

    const handleMouseUp = () => {
        setIsPasswordVisible(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowAlert(false);

        if (!email || !password) {
            setAlertMessage('Por favor, preencha todos os campos.');
            setAlertType('warning');
            setShowAlert(true);
            return;
        }

        try {
            const response = await fetch('https://api.pinnsystem.com/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('token', data.access_token);
                setAlertMessage('Login realizado com sucesso!');
                setAlertType('success');
                setShowAlert(true);
                setTimeout(() => navigate('/home/dashboard'), 2000);
            } else {
                const errorData = await response.json();
                setAlertMessage(errorData.detail || 'Erro ao fazer login.');
                setAlertType('error');
                setShowAlert(true);
            }
        } catch (error) {
            setAlertMessage('Erro de rede. Tente novamente mais tarde.');
            setAlertType('error');
            setShowAlert(true);
        }
    };

    return (
        <div className="flex h-screen bg-white">
            <div
                className="hidden md:flex flex-1 items-center justify-center bg-center"
                style={{
                    backgroundColor: "#e2e1dd",
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundSize: "80%",
                    backgroundRepeat: "no-repeat",
                    marginTop: "-60px",
                }}
            >
                <div className="absolute bottom-4 left-4 text-black text-sm">
                    PinnSystem Ltda V0.1.5
                </div>
            </div>

            <div className="flex-1 flex items-center justify-center">
                <div className="w-full max-w-md p-10 bg-white">
                    <div className="flex flex-col items-center mb-8">
                        <img src={Logo} alt="UI Unicorn Logo" className="w-20 h-20 mb-4" />
                        <h2 className="text-2xl font-semibold text-gray-800">Bem-vindo de volta! 👋</h2>
                    </div>
                    {showAlert && (
                        <Stack sx={{ width: '100%', mb: 3 }}>
                            <Alert severity={alertType} onClose={() => setShowAlert(false)}>
                                {alertMessage}
                            </Alert>
                        </Stack>
                    )}
                    <form onSubmit={handleSubmit} className="space-y-5">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">E-mail</label>
                            <input
                                type="text"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Insira seu E-mail"
                                className="mt-1 w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                        <div className="relative w-full">
                            <label
                                htmlFor="password"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Senha
                            </label>
                            <div className="mt-1 relative">
                                <input
                                    type={isPasswordVisible ? "text" : "password"}
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Insira sua senha"
                                    className="block w-full px-4 py-3 pr-12 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                />
                                <button
                                    type="button"
                                    onMouseDown={handleMouseDown} // Mostrar a senha enquanto o botão é pressionado
                                    onMouseUp={handleMouseUp} // Esconder a senha ao soltar o botão
                                    onMouseLeave={handleMouseUp} // Garantir que a senha seja escondida ao sair do botão
                                    className="absolute inset-y-0 right-3 flex items-center text-gray-500 hover:text-gray-700 focus:outline-none"
                                    aria-label="Toggle password visibility"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                        />
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.458 12C3.732 7.943 7.523 5.25 12 5.25c4.477 0 8.268 2.693 9.542 6.75-1.274 4.057-5.065 6.75-9.542 6.75-4.477 0-8.268-2.693-9.542-6.75z"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center justify-between text-sm">
                            <label className="flex items-center">
                                <input type="checkbox" className="mr-2 rounded" />
                                Lembrar-me
                            </label>
                            <a href="https://app.pinnsystem.com/forgot-password" className="text-blue-500 hover:underline">Esqueceu sua senha?</a>
                        </div>
                        <button
                            type="submit"
                            className="w-full py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                        >
                            Entrar
                        </button>
                    </form>
                    <div className="mt-6 text-center text-sm text-gray-500">
                        Não tem uma conta? <a href="https://app.pinnsystem.com/users/novos-usuarios" className="text-blue-500 hover:underline">Crie agora mesmo</a>
                    </div>
                </div>
            </div>
        </div>
    );
}
