import { useState, useEffect } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";

// Componente para seleção de categoria
function CategorySelect() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Selecione a categoria do ativo");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    document.title = `Abrir chamado - PinnSystem`;
  }, []);

  const categories = [
    "Notebook",
    "Monitor",
    "Teclado",
    "Mouse",
    "Cadeira de escritório",
    "",
  ];

  const filteredCategories = categories.filter((category) =>
    category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setIsOpen(false);
  };

  return (
    <div className="mb-4">
      <label className="block text-gray-700 font-medium">Categoria TI</label>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="w-full mt-2 p-3 border border-gray-300 rounded-lg cursor-pointer flex justify-between items-center hover:border-blue-500 focus:border-blue-500 transition"
        aria-expanded={isOpen}
        aria-haspopup="listbox"
      >
        <span>{selectedCategory}</span>
        <ChevronDownIcon className="h-5 w-5 text-gray-500" />
      </div>

      {isOpen && (
        <div className="relative mt-2 border border-gray-300 rounded-lg shadow-lg bg-white z-10">
          <input
            type="text"
            placeholder="Buscar categorias"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-3 border-b border-gray-300 focus:outline-none"
          />
          <div className="max-h-40 overflow-y-auto">
            {filteredCategories.map((category) => (
              <div
                key={category}
                onClick={() => handleCategorySelect(category)}
                className="p-3 hover:bg-blue-100 cursor-pointer transition"
                role="option"
                aria-selected={selectedCategory === category}
              >
                {category}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

// Componente principal do formulário de ativos
function ChamadoForm() {
  const [isPremium, setIsPremium] = useState(false); // Variável para verificar se é premium
  const [isBanned, setIsBanned] = useState(false);  // Adicionando variável para checar se o acesso está bloqueado

  // Função para alterar o status premium
  const togglePremium = () => {
    setIsPremium(!isPremium);
  };

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="max-w-3xl mx-auto bg-white shadow-md rounded-md p-6">
        <div className="flex items-center space-x-2 text-gray-500 text-sm">
          <span className="text-blue-500">Tickets</span>
          <span>/</span>
          <span className="font-semibold text-gray-900">Abrir chamado</span>
        </div>

        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">Criar chamado</h2>
          <div className="space-x-2">
            <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
              Abrir chamado
            </button>
          </div>
        </div>

        {/* Condição para verificar se o usuário é premium */}
        {!isPremium ? (
          <div className="bg-gray-300 p-6 rounded-lg text-center">
            <p className="text-lg text-gray-600 mb-4">Este recurso está disponível apenas para usuários Premium.</p>
            <button
              onClick={togglePremium}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Torne-se Premium
            </button>
          </div>
        ) : (
          <div className="border border-gray-300 rounded-md p-4">
            <h3 className="text-lg font-semibold mb-2">Time de TI</h3>
            <p className="text-gray-500 text-sm mb-4">Preencha as informações abaixo para abrir um chamado</p>

            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Nome completo <span className="text-red-500">*</span></label>
              <input
                type="text"
                className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Felipe Castro"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 font-medium">E-mail <span className="text-red-500">*</span></label>
              <input
                type="text"
                className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="exemplo@mail.com"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Descrição do chamado</label>
              <textarea
                className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                rows="4"
                placeholder="Lenovo IdeaPad 1 15AMN7 AMD® Ryzen 5 7520u with Radeon graphics × 8"
              ></textarea>
              <p className="text-gray-500 text-sm mt-1">Meu computador está com problemas na inicialização.</p>
            </div>

            {/* Campo de seleção de categoria */}
            <CategorySelect />
          </div>
        )}
      </div>
    </div>
  );
}

export default ChamadoForm;
