import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next'; // Importa useTranslation
import './styles.min.css';

const Profile = () => {
    const { t } = useTranslation(); // Inicializa a tradução
    const [user, setUser] = useState({});
    const [profilePicture, setProfilePicture] = useState(null);
    const [newUsername, setNewUsername] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = t('welcome'); 

        const token = localStorage.getItem('token');
        axios.get('https://api.pinnsystem.com/api/user/profile', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
            setUser(response.data);
            setProfilePicture(response.data.profile_picture);
            setNewUsername(response.data.username);
        })
        .catch(error => {
            console.error('There was an error fetching the user profile!', error);
            setAlertMessage(t('profileLoadError')); 
            setAlertSeverity('error');
        });
    }, [t]);

    useEffect(() => {
        if (alertMessage) {
            const timer = setTimeout(() => {
                setAlertMessage('');
                setAlertSeverity('');
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [alertMessage]);

    const handleProfilePictureChange = async (event) => {
        if (!event.target.files[0]) return; // No file selected
        setLoading(true);
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.post('https://api.pinnsystem.com/api/upload_profile_picture/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });
            setProfilePicture(response.data.file_path);
            setAlertMessage(t('updateProfilePictureSuccess')); 
            setAlertSeverity('success');
        } catch (error) {
            console.error('Erro ao atualizar a foto de perfil', error);
            setAlertMessage(error.response?.data?.detail || t('updateProfilePictureError')); 
            setAlertSeverity('error');
        } finally {
            setLoading(false);
        }
    };

    const handleUsernameChange = (event) => {
        setNewUsername(event.target.value);
    };

    const validateUsername = () => {
        return newUsername.trim() !== '';
    };

    const handleUsernameUpdate = async () => {
        if (!validateUsername()) {
            setAlertMessage(t('emptyUsername')); 
            setAlertSeverity('warning');
            return;
        }

        if (window.confirm(t('warning') + ': ' + t('emptyUsername'))) {
            setLoading(true);
            const token = localStorage.getItem('token');
            try {
                const response = await axios.put('https://api.pinnsystem.com/api/update_username/', { new_username: newUsername }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(prevUser => ({ ...prevUser, username: newUsername }));
                setAlertMessage(t('successUpdateUsername')); 
                setAlertSeverity('success');
            } catch (error) {
                console.error('Erro ao atualizar nome de usuário', error);
                setAlertMessage(error.response?.data?.detail || t('errorUpdateUsername')); 
                setAlertSeverity('error');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="profile-container">
            <h1>{t('welcome')}</h1>
            <div className="profile-picture-container">
                {profilePicture ? (
                    <img
                        src={`https://app.pinnsystem.com/${profilePicture}`}
                        alt="Profile"
                        className="profile-picture"
                    />
                ) : (
                    <AccountCircleIcon className="default-profile-picture" />
                )}
                <label htmlFor="profilePicture" className="edit-button">
                    {t('editPhoto')}
                </label>
                <input
                    id="profilePicture"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleProfilePictureChange}
                />
            </div>
            <div className="inde-edit-profile-container">
                <input
                    type="text"
                    value={newUsername}
                    onChange={handleUsernameChange}
                    placeholder={t('usernamePlaceholder')} 
                    className="username-input"
                    aria-label="Nome do usuário"
                />
                <button 
                    onClick={handleUsernameUpdate} 
                    className="update-button" 
                    disabled={loading}
                >
                    {loading ? 'Atualizando...' : t('updateUsername')} 
                </button>
            </div>
            <div className="admin-label-container">
                <label className="admin-label">Admin</label>
            </div>
            {alertMessage && (
                <Stack sx={{ width: '100%', marginTop: 2 }} spacing={2}>
                    <Alert severity={alertSeverity}>{alertMessage}</Alert>
                </Stack>
            )}
        </div>
    );
};

export default Profile;
