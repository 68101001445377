"use client";

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  FaUser,
  FaMapMarkerAlt,
  FaQrcode,
  FaEdit,
  FaCopy,
  FaTrash,
} from "react-icons/fa";
import GenericAssetIcon from "./GenericAssetIcon";
import { saveAs } from "file-saver";


const DetailItem = ({ label, value, children }) => (
  <div>
    <h3 className="text-sm font-medium text-gray-500">{label}</h3>
    {value ? <p className="text-gray-800">{value}</p> : children}
  </div>
);

const Dropdown = ({ isOpen, toggle, asset }) => {
  const navigate = useNavigate(); // Hook de navegação

  const handleEditClick = () => {
    if (asset?.id) {
      navigate(`https://api.pinnsystem.com/edit-asset/${asset.id}`); // Usar uma URL relativa para navegação
    }
  };

  return (
    <div className="relative">
      <button
        onClick={toggle}
        className="text-sm px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded-md shadow"
      >
        Ações
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-56 bg-white border border-gray-200 rounded-md shadow-lg z-10">
          <ul className="py-1 text-sm text-gray-700">
            {[
              { icon: FaQrcode, label: "Editar QR Code" },
              { icon: FaEdit, label: "Editar", onClick: handleEditClick },
              { icon: FaCopy, label: "Duplicar ativo" },
              { icon: FaTrash, label: "Deletar" },
            ].map(({ icon: Icon, label, onClick }) => (
              <li
                key={label}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center space-x-2"
                onClick={onClick} // Atribuindo a função de click ao item
              >
                <Icon className="text-gray-500" />
                <span>{label}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};



const Header = ({ toggleDropdown, isDropdownOpen, asset }) => (
  <div>
    {/* Caminho de navegação */}
    <div className="flex items-center space-x-2 text-gray-500 text-sm mb-4">
      <span className="text-blue-500">Ativos</span>
      <span>/</span>
      <span className="font-semibold text-gray-900">Detalhes</span>
    </div>

    {/* Imagem e informações do ativo */}
    <div className="flex items-center justify-between mb-8">
      <div className="flex items-center gap-4">
        {asset?.image_url ? (
          <img
            src={`https://api.pinnsystem.com/api/${asset.image_url}`}
            alt={asset.name}
            className="w-full h-auto max-h-40 object-contain rounded-lg mb-4"
          />
        ) : (
          <GenericAssetIcon />
        )}
        <div>
          <h1 className="text-3xl font-bold text-gray-800">{asset?.name || "Ativo sem nome"}</h1>
          <span className="inline-block px-2 py-1 text-sm font-medium text-blue-700 bg-blue-100 rounded-md">
            {asset?.status}
          </span>
        </div>
      </div>
      <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown} />
    </div>
  </div>
);


const NavigationTabs = () => (
  <div className="flex space-x-6 border-b border-gray-200 mb-6">
    {["Overview"].map((tab) => (
      <button
        key={tab}
        className={`pb-2 font-semibold ${tab === "Overview"
          ? "text-blue-500 border-b-2 border-blue-500"
          : "text-gray-500 hover:text-blue-500"
          }`}
      >
        {tab}
      </button>
    ))}
  </div>
);
const DetailsSection = ({ asset }) => {
  const createCanvasWithBrand = (imageUrl, callback) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const qrImage = new Image();

    qrImage.crossOrigin = "Anonymous";
    qrImage.src = imageUrl;

    qrImage.onload = () => {
      canvas.width = 300;
      canvas.height = 350;

      context.fillStyle = "#ffffff";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(qrImage, 50, 25, 200, 200);


      context.fillStyle = "#3b82f6";
      context.font = "16px Arial";
      context.textAlign = "center";
      context.fillText("Powered by PinnSystem", canvas.width / 2, 300);

      callback(canvas);
    };
  };

  const handleDownloadWithBrand = (asset) => {
    const imageUrl = `https://api.pinnsystem.com/uploads/qrcodes/asset_${asset?.id}.png`;

    if (!asset?.id) {
      console.error("Asset ID não está disponível.");
      return;
    }

    createCanvasWithBrand(imageUrl, (canvas) => {
      if (!canvas) {
        console.error("Erro ao criar o canvas.");
        return;
      }
      canvas.toBlob((blob) => {
        if (blob) {
          saveAs(blob, `qrcode_${asset.id}_branded.png`);
        } else {
          console.error("Erro ao gerar o blob para download.");
        }
      });
    });
  };


  const handlePrintWithBrand = (asset) => {
    const imageUrl = `https://api.pinnsystem.com/api/uploads/qrcodes/asset_${asset?.id}.png`;

    if (!asset?.id) {
      console.error("Asset ID não está disponível.");
      return;
    }

    createCanvasWithBrand(imageUrl, (canvas) => {
      if (!canvas) {
        console.error("Erro ao criar o canvas para impressão.");
        return;
      }
      const imageURLWithBrand = canvas.toDataURL("image/png");
      const printWindow = window.open();
      if (printWindow) {
        printWindow.document.write(
          `<img src="${imageURLWithBrand}" style="width:100%">`
        );
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      } else {
        console.error("Não foi possível abrir a janela de impressão.");
      }
    });
  };


  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
      {/* Informações do Ativo */}
      <div className="lg:col-span-2 space-y-6">
        <div className="grid grid-cols-2 gap-y-4 gap-x-6">
          <DetailItem label="ID" value={asset?.id || "Desconhecido"} />
          <DetailItem label="Nome" value={asset?.name || "Desconhecido"} />
          <DetailItem
            label="Fabricante"
            value={asset?.manufacturer || "Desconhecido"}
          />
          <DetailItem
            label="Número de série"
            value={asset?.serial_number || "Desconhecido"}
          />
          <DetailItem
            label="Data de Criação"
            value={asset?.created_at ? new Date(asset.created_at).toLocaleDateString('pt-BR') : "Não informada"}
          />
          <DetailItem label="Categoria">
            <span className="px-2 py-1 text-sm font-medium text-pink-600 bg-pink-100 rounded-md">
              {asset?.category || "Desconhecida"}
            </span>
          </DetailItem>
          <DetailItem label="Localização">
            <span className="px-2 py-1 text-sm font-medium text-blue-500 bg-blue-100 rounded-md">
              {asset?.location || "Desconhecida"}
            </span>
          </DetailItem>
          <DetailItem
            label="Descrição"
            value={
              asset?.technical_description || "Nenhuma descrição disponível"
            }
          />
          <DetailItem
            label="Responsável"
            value={asset?.responsible || "Ninguém atribuído"}
          />
          <DetailItem label="Valor do ativo">
            <span className="px-2 py-1 text-sm font-medium text-green-600 bg-green-100 rounded-md">
              R${asset?.value || "Não informado"}
            </span>
          </DetailItem>
          <DetailItem
            label="Observações"
            value={asset?.notes || "Sem observações"}
          />
        </div>
      </div>

      {/* QR Code */}
      <div className="flex flex-col items-center justify-center space-y-4 bg-white shadow-md p-6 rounded-md">
        <img
          src={`https://api.pinnsystem.com/api/uploads/qrcodes/asset_${asset.id}.png`}
          alt={`QR Code para o ativo ${asset.name}`}
          className="w-48 h-48 bg-white p-2 rounded-md shadow-md border border-gray-300"
        />
        <p>
          {asset?.name || "Nome do ativo"}
        </p>
        <p className="text-xs text-gray-500">
          Powered by{" "}
          <span className="font-semibold text-blue-500">PinnSystem</span>
        </p>
        <div className="flex space-x-2">
          <button
            onClick={() => handleDownloadWithBrand(asset)}
            className="bg-blue-500 text-white px-4 py-2 text-xs rounded hover:bg-blue-600 transition"
          >
            Download
          </button>
          <button
            onClick={() => handlePrintWithBrand(asset)}
            className="bg-blue-500 text-white px-4 py-2 text-xs rounded hover:bg-blue-600 transition"
          >
            Imprimir
          </button>
        </div>
      </div>
    </div>
  );
};


const AssetOverview = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [asset, setAsset] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAsset = async () => {
      try {
        const response = await axios.get(`https://api.pinnsystem.com/api/assets/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        console.log("Ativo recebido:", response.data);
        setAsset(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Erro ao buscar o ativo:", error);
        setIsLoading(false);
      }
    };

    fetchAsset();
  }, [id]);

  if (isLoading) {
    return <p>Carregando...</p>;
  }

  if (!asset) {
    return <p>Ativo não encontrado.</p>;
  }

  return (
    <div className="max-w-4xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-md border border-gray-200">
      <Header
        toggleDropdown={() => setIsDropdownOpen(!isDropdownOpen)}
        isDropdownOpen={isDropdownOpen}
        asset={asset}
      />
      <NavigationTabs />
      <DetailsSection asset={asset} />
    </div>
  );
};

export default AssetOverview;
