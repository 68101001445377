import React, { useEffect, useState } from "react";
import axios from "axios";

const RecentAssets = ({ assets }) => {
  if (assets.length === 0) {
    return (
      <div className="bg-white p-4 rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-4">
          Ativos Adicionados e Editados Recentemente
        </h2>
        <p className="text-gray-500">Nenhum ativo foi adicionado ou editado recentemente.</p>
      </div>
    );
  }

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4">
        Ativos Adicionados e Editados Recentemente
      </h2>
      <div className="space-y-4">
        {assets.map((asset, index) => (
          <div
            key={index}
            className="flex items-center space-x-4 p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition"
          >
            <div className="flex-1">
              <h3 className="text-md font-medium text-gray-800">{asset.name}</h3>
              <p className="text-sm text-gray-500">{asset.technical_description}</p>
              <div className="flex items-center text-sm mt-1">
                <span
                  className={`px-2 py-1 rounded-full text-xs font-semibold ${
                    asset.status.toLowerCase() === "disponivel"
                      ? "bg-green-100 text-green-700"
                      : "bg-yellow-200 text-yellow-700"
                  }`}
                >
                  {asset.status}
                </span>
                <span className="text-xs ml-2 text-gray-400">
                  (Última modificação: {new Date(asset.updated_at).toLocaleString()})
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default function Dashboard() {
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRecentAssets = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("https://api.pinnsystem.com/api/dashboard/recent-assets", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Limitar aos 3 últimos ativos
        const recentAssets = response.data.slice(-3).reverse(); // Pega os últimos 3 e inverte a ordem
        setAssets(recentAssets);
      } catch (error) {
        console.error("Erro ao buscar ativos recentes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecentAssets();
  }, []);

  return (
    <div className="p-8">
      {loading ? (
        <p>Carregando ativos recentes...</p>
      ) : (
        <RecentAssets assets={assets} />
      )}
    </div>
  );
}
