import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaUsers } from 'react-icons/fa';

export default function UsageSelectionPage() {
    const [selectedUsage, setSelectedUsage] = useState('');
    const [howHeard, setHowHeard] = useState('');
    const navigate = useNavigate();

    const handleSelection = (selection) => {
        setSelectedUsage(selection);
    };

    const handleSubmit = () => {
        console.log("Uso selecionado:", selectedUsage);
        console.log("Como conheceu o PinnSystem:", howHeard);
        navigate('/auth/login'); // Redireciona para a página de login
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-50 to-blue-200 p-4" 
            style={{
                background: 'repeating-radial-gradient(circle, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1) 20px, transparent 20px, transparent 40px)',
            }}>
            <div className="w-full max-w-md p-6 bg-white rounded-xl shadow-xl">
                <img src="/pinn.png" alt="Logo" className="w-16 h-16 mx-auto mb-4" />
                <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">Estamos felizes que você está conosco!</h2>
                <p className="text-center text-gray-600 mb-6 text-sm">
                    Para nos ajudar a melhorar sua experiência, nos diga como você pretende usar o PinnSystem e como nos conheceu.
                </p>
                
                {/* Seleção de uso */}
                <div className="space-y-4">
                    <div onClick={() => handleSelection('Personal')} className={`p-4 border rounded-xl cursor-pointer shadow-md hover:bg-blue-50 transition duration-300 flex items-center ${selectedUsage === 'Personal' ? 'border-blue-500' : 'border-gray-300'}`}>
                        <FaUser className="text-blue-500 text-3xl mr-3" />
                        <div>
                            <h3 className="text-lg font-semibold text-gray-800">Uso Pessoal</h3>
                            <p className="text-sm text-gray-600 mt-1">
                                Quero usar o PinnSystem para minhas necessidades pessoais e explorar seus recursos.
                            </p>
                        </div>
                    </div>
                    <div onClick={() => handleSelection('Team')} className={`p-4 border rounded-xl cursor-pointer shadow-md hover:bg-purple-50 transition duration-300 flex items-center ${selectedUsage === 'Team' ? 'border-purple-500' : 'border-gray-300'}`}>
                        <FaUsers className="text-purple-500 text-3xl mr-3" />
                        <div>
                            <h3 className="text-lg font-semibold text-gray-800">Uso em Equipe</h3>
                            <p className="text-sm text-gray-600 mt-1">
                                Pretendo usar o PinnSystem em equipe, com múltiplos usuários gerenciando o inventário.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Pergunta sobre como conheceu */}
                <div className="mt-6">
                    <h3 className="text-lg font-semibold text-gray-800 mb-2">Como você conheceu o PinnSystem?</h3>
                    <select 
                        className="w-full p-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-800" 
                        value={howHeard} 
                        onChange={(e) => setHowHeard(e.target.value)}>
                        <option value="">Selecione uma opção</option>
                        <option value="Social Media">Redes Sociais</option>
                        <option value="Indicação">Indicação de amigos</option>
                        <option value="Google">Pesquisa no Google</option>
                        <option value="Publicidade">Publicidade</option>
                        <option value="Outro">Outro</option>
                    </select>
                </div>

                {/* Botão para submeter */}
                <div className="mt-6">
                    <button 
                        onClick={handleSubmit} 
                        className="w-full px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-lg hover:bg-blue-600 transition duration-300">
                        Finalizar
                    </button>
                </div>
            </div>
        </div>
    );
}
