import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import GenericAssetIcon from './GenericAssetIcon'; // Importando o SVG genérico
import AssetDetailsModal from './AssetDetailsModal'; // Importando o modal

function InventoryPage() {
  const [assets, setAssets] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [selectedAsset, setSelectedAsset] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (asset) => {
    navigate(`/asset/${asset.id}`);
  };
  

  

  const closeModal = () => {
    setSelectedAsset(null);
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/cadastro/ativos');
  };

  useEffect(() => {
    document.title = `Ativos - PinnSystem`;

    // Função para buscar os ativos
    const fetchAssets = async () => {
      try {
        const response = await axios.get('https://api.pinnsystem.com/api/assets', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setAssets(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Erro ao buscar os ativos:", error);
        setIsLoading(false);
      }
    };

    fetchAssets();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-white p-8">
      {/* Header */}
      <div className="flex items-center space-x-2 text-gray-500 text-sm">
        <span className="text-blue-600 font-medium">Ativos</span>
        <span>/</span>
        <span className="font-semibold text-gray-900">Inventário</span>
      </div>

      {/* Title */}
      <div className="flex justify-between items-center mt-4">
        <h1 className="text-3xl font-bold text-gray-800">Inventário de Ativos</h1>
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600 transition"
          onClick={handleRedirect}
        >
          + Novo Ativo
        </button>
      </div>

      {/* Filter and Action Section */}
      <div className="flex justify-between items-center mt-6">
        {/* Filter Bar */}
        <div className="relative w-full max-w-md">
          <input
            type="text"
            placeholder="Procurar por ativos"
            className="px-4 py-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-600 transition"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className="absolute right-0 top-0 h-full px-4 bg-blue-500 text-white rounded-r-md">
            Filtrar
          </button>
        </div>
      </div>

      {/* Assets List */}
      <div className="mt-10">
        {isLoading ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Loading Skeleton */}
            {Array.from({ length: 6 }).map((_, index) => (
              <div key={index} className="p-6 bg-white rounded-lg shadow animate-pulse">
                <div className="h-6 bg-gray-300 rounded w-3/4 mb-4"></div>
                <div className="h-4 bg-gray-300 rounded w-1/2 mb-2"></div>
                <div className="h-4 bg-gray-300 rounded w-2/3 mb-2"></div>
                <div className="h-4 bg-gray-300 rounded w-1/4"></div>
              </div>
            ))}
          </div>
        ) : assets.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {assets
              .filter(asset =>
                asset.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((asset) => (
                <div
                  key={asset.id}
                  className="flex flex-col bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition cursor-pointer"
                  onClick={() => openModal(asset)}
                >
                  {asset.image_url ? (
                    <img
                      src={`https://api.pinnsystem.com/api/${asset.image_url}`}
                      alt={asset.name}
                      className="w-full h-auto max-h-40 object-contain rounded-lg mb-4"
                      onError={(e) => {
                        e.target.onerror = null; // Para evitar loop infinito
                        e.target.src = '/path/to/default-image.png'; // Caminho para uma imagem padrão caso falhe
                      }}
                    />
                  ) : (
                    <GenericAssetIcon /> // Mostra o SVG genérico se não houver imagem
                  )}
                  <h2 className="text-xl font-semibold text-gray-800">{asset.name}</h2>
                  <p className="text-sm text-gray-500">{asset.category}</p>
                  <p className="text-sm text-gray-500">{asset.responsible}</p>
                  <span className={`mt-2 px-4 py-1 text-sm font-semibold rounded-full 
                    ${asset.status === 'Disponivel' || asset.status === 'Disponível' ? 'bg-green-200 text-green-800' :
                    asset.status === 'Em uso' ? 'bg-yellow-200 text-yellow-800' :
                      asset.status === 'Em manuntenção' ? 'bg-red-200 text-red-800' : 'bg-gray-200 text-gray-800'}
                    flex items-center justify-center gap-2`}>
                    {asset.status === 'Disponivel' || asset.status === 'Disponível' ? (
                      <div className="w-2.5 h-2.5 bg-green-500 rounded-full"></div>
                    ) : asset.status === 'Em uso' ? (
                      <div className="w-2.5 h-2.5 bg-yellow-500 rounded-full"></div>
                    ) : asset.status === 'Em manuntenção' ? (
                      <div className="w-2.5 h-2.5 bg-red-500 rounded-full"></div>
                    ) : (
                      <div className="w-2.5 h-2.5 bg-gray-500 rounded-full"></div>
                    )}
                    {asset.status}
                  </span>
                </div>
              ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center mt-20 text-center">
            <div className="w-24 h-24 bg-gray-200 rounded-lg flex items-center justify-center relative">
              <div className="w-14 h-8 bg-gray-300 rounded-sm"></div>
              <div className="absolute bottom-1 w-4 h-1 bg-gray-500 rounded-full"></div>
              <div className="absolute bottom-1 right-4 w-2 h-2 bg-gray-400 rounded-full"></div>
            </div>
            <p className="mt-6 text-lg font-semibold text-gray-700">Nenhum ativo cadastrado</p>
            <p className="mt-2 text-gray-500">O que está esperando? Crie seu primeiro ativo agora mesmo!</p>
          </div>
        )}
      </div>

      {/* Modal */}
      {isModalOpen && selectedAsset && (
        <AssetDetailsModal asset={selectedAsset} onClose={closeModal} />
      )}
    </div>
  );
}

export default InventoryPage;