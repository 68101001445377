import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const CustomFields = () => {
  const [showAlert, setShowAlert] = useState(false);

  const handleButtonClick = () => {
    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 5000); // O alerta desaparecerá após 5 segundos
  };

  return (
    <div className="p-6 bg-white border border-gray-200 rounded-lg shadow-md">
      {/* Título principal */}
      <div className="mb-4">
        <h2 className="text-lg font-semibold text-gray-800">Campos personalizados</h2>
        <a
          href="#"
          className="text-blue-500 text-sm hover:underline"
        >
          Crie seus próprios campos personalizados
        </a>
      </div>

      {/* Alerta */}
      {showAlert && (
        <Stack sx={{ width: "100%" }} spacing={2} className="mb-4">
          <Alert severity="warning">
            Essa funcionalidade não está disponível no plano gratuito.{" "}
            <a href="https://app.pinnsystem.com/mudar-plano" className="text-blue-500 underline">
              Faça o upgrade
            </a>{" "}
            para acessá-la.
          </Alert>
        </Stack>
      )}

      {/* Área principal */}
      <div className="flex flex-col items-center justify-center p-8 border border-gray-200 rounded-md bg-gray-50">
        <div className="mb-4">
          {/* Ícone de lupa */}
          <div className="flex items-center justify-center w-12 h-12 rounded-full bg-gray-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6 text-gray-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-4.35-4.35m0 0A7.5 7.5 0 1116.65 4.6a7.5 7.5 0 010 11.05z"
              />
            </svg>
          </div>
        </div>
        {/* Texto */}
        <p className="text-gray-600 font-medium mb-4">Sem campos personalizados adicionados</p>
        {/* Botão */}
        <button
          onClick={handleButtonClick}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
        >
          Criar campos personalizados
        </button>
        {/* Texto para upgrade */}
        <p className="text-gray-500 text-sm mt-4">
          Para criar campos personalizados,{" "}
          <a
            href="https://app.pinnsystem.com/mudar-plano"
            className="text-blue-500 hover:underline"
          >
            faça upgrade do seu plano
          </a>.
        </p>
      </div>
    </div>
  );
};

export default CustomFields;
