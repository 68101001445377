import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import BackgroundImage from '../../assets/images/2024-11-15_09-30.png';
import Logo from '../../assets/images/pinn.png';
import axios from 'axios';

export default function LoginPage() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [domainName, setDomainName] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [showEmailAlert, setShowEmailAlert] = useState(false);

    // Atualiza o título da página
    useEffect(() => {
        document.title = 'Novos usuários - PinnSystem';
    }, []);

    // Verifica se o domínio já existe
    const checkDomainExists = async () => {
        try {
            const response = await axios.get(`https://api.pinnsystem.com/api/domain-exists/${domainName}`);
            return response.data.exists;
        } catch (error) {
            setError('Erro ao verificar domínio');
            return false;
        }
    };

    // Função de registro do usuário
    const handleRegister = async (e) => {
        e.preventDefault();
        setError(''); // Limpa erros anteriores
        const domainExists = await checkDomainExists();

        if (!domainExists) {
            try {
                await axios.post('https://api.pinnsystem.com/api/register-domain/', { name: domainName });
            } catch (error) {
                setError('Erro ao criar domínio');
                return;
            }
        }

        try {
            await axios.post('https://api.pinnsystem.com/api/register-user/', {
                email,
                password,
                username,
                department: '',
                domain_name: domainName
            });
            setSuccess(true);
            setShowEmailAlert(true); // Exibe o alerta de verificação de e-mail
        } catch (error) {
            setError('Erro ao criar usuário');
        }
    };

    return (
        <div className="flex h-screen bg-white">
            {/* Coluna da esquerda com imagem de fundo */}
            <div
                className="hidden md:flex flex-1 items-center justify-center bg-center"
                style={{
                    backgroundColor: '#e2e1dd',
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundSize: '80%',
                    backgroundRepeat: 'no-repeat',
                    marginTop: '-60px'
                }}
            >
                <div className="absolute bottom-4 left-4 text-black text-sm">
                    PinnSystem Ltda V0.1.5
                </div>
            </div>

            {/* Coluna da direita com formulário */}
            <div className="flex-1 flex items-center justify-center">
                <div className="w-full max-w-sm p-6 bg-white md:p-10">
                    {/* Alerta de sucesso */}
                    {success && showEmailAlert && (
                        <Stack sx={{ width: '100%', marginBottom: 2 }} spacing={2}>
                            <Alert severity="info">Por favor, verifique seu e-mail para ativar sua conta.</Alert>
                        </Stack>
                    )}

                    {/* Cabeçalho do formulário */}
                    <div className="flex flex-col items-center mb-4">
                        <img src={Logo} alt="UI Unicorn Logo" className="w-16 h-16 mb-3" />
                        <h2 className="text-xl font-semibold text-gray-800">Crie sua conta</h2>
                        <div className="mt-3 text-center text-xs text-gray-500">
                            Já tem uma conta?{' '}
                            <a href="/auth/login" className="text-blue-500 hover:underline">
                                Entre agora!
                            </a>
                        </div>
                    </div>

                    {/* Formulário de registro */}
                    <form className="space-y-3" onSubmit={handleRegister}>
                        <div>
                            <label htmlFor="email" className="block text-xs font-medium text-gray-700">
                                E-mail
                            </label>
                            <input
                                type="text"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Insira seu E-mail"
                                className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"
                            />
                        </div>

                        <div>
                            <label htmlFor="username" className="block text-xs font-medium text-gray-700">
                                Nome de usuário
                            </label>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Nome de usuário"
                                className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"
                            />
                        </div>

                        <div>
                            <label htmlFor="domainName" className="block text-xs font-medium text-gray-700">
                                Domínio (Empresa)
                            </label>
                            <input
                                type="text"
                                id="domainName"
                                value={domainName}
                                onChange={(e) => setDomainName(e.target.value)}
                                placeholder="Nome da empresa"
                                className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"
                            />
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-xs font-medium text-gray-700">
                                Senha
                            </label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Insira sua senha"
                                className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-lg text-xs focus:outline-none focus:ring-1 focus:ring-blue-500"
                            />
                        </div>

                        {/* Exibição de erro */}
                        {error && <p className="text-red-500 text-xs">{error}</p>}

                        <button
                            type="submit"
                            className="w-full py-2 bg-blue-500 text-white text-sm rounded-lg hover:bg-blue-600 transition-colors"
                        >
                            Criar conta
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
