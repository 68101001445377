import { useState, useEffect } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import axios from "axios";
import CustomFields from "./CustomFields"
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

// Componente para seleção de localização
function LocationSelect({ value, onChange }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const locations = [
    "Acre (AC)",
    "Alagoas (AL)",
    "Amapá (AP)",
    "Amazonas (AM)",
    "Bahia (BA)",
    "Ceará (CE)",
    "Distrito Federal (DF)",
    "Espírito Santo (ES)",
    "Goiás (GO)",
    "Maranhão (MA)",
    "Mato Grosso (MT)",
    "Mato Grosso do Sul (MS)",
    "Minas Gerais (MG)",
    "Pará (PA)",
    "Paraíba (PB)",
    "Paraná (PR)",
    "Pernambuco (PE)",
    "Piauí (PI)",
    "Rio de Janeiro (RJ)",
    "Rio Grande do Norte (RN)",
    "Rio Grande do Sul (RS)",
    "Rondônia (RO)",
    "Roraima (RR)",
    "Santa Catarina (SC)",
    "São Paulo (SP)",
    "Sergipe (SE)",
    "Tocantins (TO)"
  ];
  

  const filteredLocations = locations.filter((location) =>
    location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleLocationSelect = (location) => {
    onChange(location); // Atualiza o valor no formulário
    setShowDropdown(false); // Fecha o dropdown
  };

  return (
    <div className="mb-4">
      <label className="block text-gray-700 font-medium">Localização</label>
      <div
        onClick={() => setShowDropdown(!showDropdown)}
        className="w-full mt-2 p-3 border border-gray-300 rounded-lg cursor-pointer flex justify-between items-center hover:border-blue-500 focus:border-blue-500 transition"
        aria-expanded={showDropdown}
        aria-haspopup="listbox"
      >
        <span>{value || "Selecionar localização"}</span>
        <ChevronDownIcon className="h-5 w-5 text-gray-500" />
      </div>

      {showDropdown && (
        <div className="relative mt-2 border border-gray-300 rounded-lg shadow-lg bg-white z-10">
          <input
            type="text"
            placeholder="Buscar localizações"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-3 border-b border-gray-300 focus:outline-none"
          />
          <div className="max-h-40 overflow-y-auto">
            {filteredLocations.map((location) => (
              <div
                key={location}
                onClick={() => handleLocationSelect(location)}
                className="p-3 hover:bg-blue-100 cursor-pointer transition"
                role="option"
                aria-selected={value === location}
              >
                {location}
              </div>
            ))}
          </div>
          <button
            className="w-full text-left p-3 text-blue-600 text-sm font-medium hover:bg-blue-100 transition"
            onClick={() => alert("Criar nova localização")}
          >
            + Criar nova localização
          </button>
        </div>
      )}

      <p className="text-gray-500 text-xs mt-2">
        A localização é onde o item deve estar. É diferente da última localização escaneada.{" "}
        <a href="#" className="text-blue-600 underline hover:text-blue-700">Criar localizações</a>
      </p>
    </div>
  );
}


// Componente para seleção de categoria
function CategorySelect({ value, onChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const categories = [
    "Notebook",
    "Monitor",
    "Teclado",
    "Mouse",
    "Cadeira de escritório",
    "Cabos e Periféricos",
  ];

  const filteredCategories = categories.filter((category) =>
    category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCategorySelect = (category) => {
    onChange(category); // Atualiza o valor no formulário
    setIsOpen(false); // Fecha o dropdown
  };

  return (
    <div className="mb-4">
      <label className="block text-gray-700 font-medium">Categoria TI</label>
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="w-full mt-2 p-3 border border-gray-300 rounded-lg cursor-pointer flex justify-between items-center hover:border-blue-500 focus:border-blue-500 transition"
        aria-expanded={isOpen}
        aria-haspopup="listbox"
      >
        <span>{value || "Selecione a categoria do ativo"}</span>
        <ChevronDownIcon className="h-5 w-5 text-gray-500" />
      </div>

      {isOpen && (
        <div className="relative mt-2 border border-gray-300 rounded-lg shadow-lg bg-white z-10">
          <input
            type="text"
            placeholder="Buscar categorias"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-3 border-b border-gray-300 focus:outline-none"
          />
          <div className="max-h-40 overflow-y-auto">
            {filteredCategories.map((category) => (
              <div
                key={category}
                onClick={() => handleCategorySelect(category)}
                className="p-3 hover:bg-blue-100 cursor-pointer transition"
                role="option"
                aria-selected={value === category}
              >
                {category}
              </div>
            ))}
          </div>

          <button
            className="w-full text-left p-3 text-blue-600 text-sm font-medium hover:bg-blue-100 transition"
            onClick={() => alert("Criar nova localização")}
          >
            + Criar nova categoria
          </button>
        </div>
      )}
    </div>
  );
}


// Componente principal do formulário de ativos
function AssetForm() {
  const [formData, setFormData] = useState({
    name: "",
    manufacturer: "",
    image_url: null,
    technical_description: "",
    serial_number: "",
    category: "",
    operatingSystem: "",
    status: "",
    responsible: "",
    location: "",
    value: "",
    notes: "",
  });

  const [alert, setAlert] = useState({ type: "", message: "", show: false });

  const handleCategoryChange = (category) => {
    setFormData((prev) => ({
      ...prev,
      category,
      operatingSystem: category === "Notebook" ? "" : undefined, // Reseta se for Notebook
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      image_url: e.target.files.length > 0 ? e.target.files[0] : null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null) {
        // Não envia valores nulos
        formDataToSend.append(key, formData[key]);
      }
    });

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://api.pinnsystem.com/api/new-assets",
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setAlert({
        type: "success",
        message: "Ativo cadastrado com sucesso!",
        show: true,
      });

      // Limpa o formulário
      setFormData({
        name: "",
        manufacturer: "",
        image_url: null,
        technical_description: "",
        serial_number: "",
        category: "",
        operatingSystem: "",
        status: "",
        responsible: "",
        location: "",
        value: "",
        notes: "",
      });
    } catch (error) {
      console.error("Erro ao cadastrar o ativo:", error.response?.data || error.message);
      setAlert({
        type: "error",
        message: "Erro ao cadastrar o ativo. Confira as informações e tente novamente.",
        show: true,
      });
    } finally {
      setTimeout(() => setAlert({ type: "", message: "", show: false }), 5000);
    }
  };
  

  return (
    <form onSubmit={handleSubmit} className="min-h-screen bg-gray-50 p-8">
      <div className="max-w-3xl mx-auto bg-white shadow-md rounded-md p-6">
        <div className="flex items-center space-x-2 text-gray-500 text-sm">
          <span className="text-blue-500">Ativos</span>
          <span>/</span>
          <span className="font-semibold text-gray-900">Novo ativo</span>
        </div>

        {/* Alertas */}
        {alert.show && (
          <Stack sx={{ width: "100%", mb: 4 }} spacing={2}>
            <Alert severity={alert.type}>{alert.message}</Alert>
          </Stack>
        )}

        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">
            {formData.name || "Ativo sem nome"} {/* Exibe "Ativo sem nome" se o campo estiver vazio */}
          </h2>
          <div className="space-x-2">
            <button
              type="button"
              className="px-4 py-2 bg-white text-gray-700 border border-gray-300 rounded-md hover:bg-gray-100"
            >
              Cancelar
            </button>
            {/* <button
            type="button"
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          >
            Adicionar outro
          </button> */}
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Salvar ativo
            </button>
          </div>
        </div>

        <div className="border border-gray-300 rounded-md p-4">
          <h3 className="text-lg font-semibold mb-2">Campos básicos</h3>
          <p className="text-gray-500 text-sm mb-4">
            Preencha as informações abaixo para cadastrar um novo ativo
          </p>

          {/* Campo para Nome do Ativo */}
          <div className="mb-4">
            <label className="block text-gray-700 font-medium">
              Nome do ativo <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="PINN00000001"
            />
          </div>


          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Fabricante <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="manufacturer"
              value={formData.manufacturer}
              onChange={handleChange}
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Imagem do ativo</label>
            <p className="text-gray-500 text-sm mb-2">Accepts PNG, JPG or JPEG (max. 4 MB)</p>
            <input
              type="file"
              name="image_url"
              className="block w-full text-sm text-blue-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:bg-blue-100 file:text-gray-700 hover:file:bg-gray-200"
              accept=".png, .jpg, .jpeg"
              onChange={handleImageChange}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Descrição técnica <span className="text-red-500">*</span></label>
            <textarea
              name="technical_description"
              value={formData.technical_description}
              onChange={handleChange}
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="4"
              placeholder="Lenovo IdeaPad 1 15AMN7 AMD® Ryzen 5 7520u with radeon graphics × 8"
            ></textarea>
            <p className="text-gray-500 text-sm mt-1">Esta é a descrição inicial do objeto. Ela será mostrada na página de visão geral do ativo. Você sempre pode alterá-la. Máximo de 1000 caracteres.</p>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Número de série <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="serial_number"
              value={formData.serial_number}
              onChange={handleChange}
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="SN: ABCD-EF12-3456-7890"
              required
            />
          </div>

          {/* Campo de seleção de categoria */}
          <CategorySelect
            value={formData.category}  // O valor da categoria selecionada
            onChange={(category) => setFormData({ ...formData, category })}  // Atualiza o estado com a categoria selecionada
          />

          {formData.category === "Notebook" && (
            <div className="mb-4">
              <label className="block text-gray-700 font-medium">Sistema Operacional</label>
              <input
                type="text"
                name="operatingSystem"
                value={formData.operatingSystem}
                onChange={handleChange}
                className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Ex: Windows 11"
              />
            </div>
          )}


          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Status do Ativo <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="status"
              value={formData.status}
              onChange={handleChange}
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Disponível"
              required
            />
            <p className="text-gray-500 text-sm mt-1">Os status podem ajudar você a organizar seu banco de dados. Eles podem ser combinados. <a href="#" className="text-blue-600">Criar status personalizados</a></p>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Responsável pelo ativo <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="responsible"
              value={formData.responsible}
              onChange={handleChange}
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Felipe Castro"
              required
            />
            <p className="text-gray-500 text-sm mt-1">Esse é o usuário atual que está responsável pelo ativo <a href="#" className="text-blue-600">Criar lista de usuários</a></p>
          </div>

          {/* Campo de seleção de localização */}
          <LocationSelect
            value={formData.location}  // O valor da localização selecionada
            onChange={(location) => setFormData({ ...formData, location })}  // Atualiza o estado com a localização selecionada
          />


          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Valor do ativo <span className="text-red-500">*</span></label>
            <div className="flex mt-1">
              <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-100 text-gray-500">R$</span>
              <input
                type="number"
                name="value"
                value={formData.value}
                onChange={handleChange}
                className="flex-1 block w-full px-4 py-2 border border-gray-300 rounded-r-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="5000,00"
                required
              />
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Observações</label>
            <textarea
              name="notes"
              value={formData.notes}
              onChange={handleChange}
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="4"
              placeholder="Alguma observação adicional sobre o ativo"
            ></textarea>
          </div>

          <CustomFields />
        </div>
      </div>
    </form>
  );
}
export default AssetForm;
