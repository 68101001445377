import React, { useState, useEffect } from "react";
import { BrowserQRCodeReader } from "@zxing/browser";

const QrCodeScanner = () => {
  const [error, setError] = useState(null);
  const [isScanning, setIsScanning] = useState(false);
  const [codeReader, setCodeReader] = useState(null); // Estado para armazenar a instância do leitor

  const startScanner = async () => {
    setError(null);

    // Parar a instância existente, se houver
    if (codeReader) {
      try {
        codeReader.stop(); // Garantir que a instância atual seja parada
      } catch (e) {
        console.warn("Erro ao parar o leitor:", e);
      }
    }

    const newCodeReader = new BrowserQRCodeReader();
    setCodeReader(newCodeReader); // Atualizar o estado com a nova instância

    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoInputDevices = devices.filter((device) => device.kind === "videoinput");

      if (videoInputDevices.length > 0) {
        const selectedDeviceId = videoInputDevices[0].deviceId;
        setIsScanning(true);

        // Decodificar do vídeo
        newCodeReader.decodeFromVideoDevice(
          selectedDeviceId,
          "video", // ID do elemento <video>
          (result, err) => {
            if (result) {
              window.location.href = result.text; // Navegar para o resultado
              newCodeReader.stop();
              setIsScanning(false);
            }

            if (err && err.name !== "NotFoundException") {
              console.error(err);
              setError("Erro ao escanear QR Code.");
              setIsScanning(false);
              newCodeReader.stop();
            }
          }
        );
      } else {
        setError("Nenhuma câmera encontrada.");
      }
    } catch (err) {
      console.error(err);
      setError("Erro ao acessar a câmera.");
    }
  };

  // Limpar o leitor ao desmontar o componente
  useEffect(() => {
    return () => {
      if (codeReader) {
        try {
          codeReader.stop();
        } catch (e) {
          console.warn("Erro ao parar o leitor ao desmontar:", e);
        }
      }
    };
  }, [codeReader]);

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      {/* Cabeçalho */}
      <header className="bg-white text-gray-800 py-4">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <h1 className="text-xl font-bold">Ativos / Scanner</h1>
        </div>
      </header>

      {/* Conteúdo principal */}
      <main className="flex flex-col items-center justify-center flex-grow p-6">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">Leitor de QR Code</h2>
        <video
          id="video"
          className="rounded-lg shadow-md border-2 border-gray-300 w-full max-w-md"
          autoPlay
          playsInline
        ></video>

        {!isScanning && (
          <button
            onClick={startScanner}
            className="mt-6 py-2 px-6 bg-blue-500 text-white font-medium rounded-lg shadow hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            Iniciar Scanner
          </button>
        )}

        {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
      </main>
    </div>
  );
};

export default QrCodeScanner;
