import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './styles.min.css';
import logo from '../../../assets/images/pinn_background_white.png'; 
import collapsedLogo from '../../../assets/images/pinn.png'; 
import DevicesIcon from '@mui/icons-material/Devices';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

const Sidebar = ({ isExpanded, setIsExpanded }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const formatUsername = (username) => {
    if (!username) return '';
    return username
      .split('.')
      .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
      .join(' ');
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token'); 
    window.location.href = '/auth/login'; 
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className={`sidebar ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div className="sidebar-header">
        {/* Mostrar logo diferente dependendo se está expandido ou colapsado */}
        <img 
          src={isExpanded ? logo : collapsedLogo} 
          alt={t('company_logo')} 
          className="logo" 
        />
        <button className="menu-btn" onClick={() => setIsExpanded(!isExpanded)}>
          ☰
        </button>
        {isExpanded && data && (
          <div className="data">
            {data.profile_picture ? (
              <img
                src={`https://app.pinnsystem.com/${data.profile_picture}`}
                alt="Profile"
                style={{ width: '50px', height: '50px', borderRadius: '50%' }}
              />
            ) : (
              <AccountCircleIcon style={{ fontSize: '50px' }} />
            )}
            <span>{formatUsername(data.username)}</span>
          </div>
        )}
      </div>
      <nav>
        <ul>
          <li>
            <h3>{t('Home')}</h3>
            <NavLink to="/home/dashboard" activeClassName="active">
              {/* Icone aqui */}
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
              </svg>
              {isExpanded && <span style={{ marginLeft: '8px' }}>{t('Dashboard')}</span>}
            </NavLink>
          </li>
          <li>
            <h3>{t('Gestão de ativos')}</h3>
            <ul>
              <li>
                <NavLink to="/cadastro/ativos" activeClassName="active">
                  <DevicesIcon />
                  {isExpanded && <span style={{ marginLeft: '8px' }}>{t('Cadastrar ativo')}</span>}
                </NavLink>
              </li>
              <li>
                <NavLink to="/gerenciamento/ativos" activeClassName="active">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 3.75H6A2.25 2.25 0 0 0 3.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0 1 20.25 6v1.5m0 9V18A2.25 2.25 0 0 1 18 20.25h-1.5m-9 0H6A2.25 2.25 0 0 1 3.75 18v-1.5M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  </svg>
                  {isExpanded && <span style={{ marginLeft: '8px' }}>{t('Ver ativos')}</span>}
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <h3>{t('Licença de Software')}</h3>
            <NavLink to="/gerenciamento/licencas" activeClassName="active">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
              </svg>
              {isExpanded && <span style={{ marginLeft: '8px' }}>{t('Licenças')}</span>}
            </NavLink>
          </li>
          <li>
            <h3>{t('Scanner')}</h3>
            <NavLink to="/scan/ativo" activeClassName="active">
            <QrCodeScannerIcon />
              {isExpanded && <span style={{ marginLeft: '8px' }}>{t('Fazer scan')}</span>}
            </NavLink>
          </li>
          <li>
            <h3>{t('Chamados')}</h3>
            <NavLink to="/criar-chamado" activeClassName="active">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5" />
              </svg>
              {isExpanded && <span style={{ marginLeft: '8px' }}>{t('Abrir chamados')}</span>}
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="logout-section">
        <NavLink to="/auth/login" onClick={handleLogout} activeClassName="active">
          <ExitToAppIcon />
          {isExpanded && <span style={{ marginLeft: '8px' }}>{t('Sair')}</span>}
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
