import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './components/Login/index';
import RegisterPage from './components/Register/index'
import Dashboard from './components/main/Dashboard';
import AssetForm from './components/ativos/index';
import InventoryPage from './components/Assets_Tables';
import InventoryLicensePage from './components/Licenças';
import ChamadosForm from './components/Chamados/Form';
import PrivateRoute from './components/PrivateRoute';
import MainLayout from './components/MainLayout';
import AxiosInterceptor from './components/AxiosInterceptor';
import NetworkError from './components/NetworkError';
import Profile from './components/Controle/index';
import InterApp from './components/Integrations/index';
import PageNotFound from './components/PageNotFound';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import UsageSelectionPage from './components/Register/UsageSelectionPage';
import AccountDetails from './components/Profile';
import Subscription from './components/Billing'
import AssetDetails from './components/main/FilterBy'
import TawkScript from "./Tawk"
import AssetPage from './components/main/AssetPage';
import QrCodeScanner from "./components/QRcode"
import AssetOverview from "./components/Assets_Tables/AssetDetails"
import AssetFormEdit from './components/Assets_Tables/EditAssets';
import VerifyAccount from './components/Verify_email';

// Bloquear logs no ambiente de produção
if (process.env.NODE_ENV === 'production') {
  console.log = () => { };
  console.warn = () => { };
  console.error = () => { };
}

const App = () => {
  return (
    <Router>
      <AxiosInterceptor>
        <Routes> 
          <Route path="/" element={<Navigate to="/auth/login" />} />
          <Route path="/usage-selection" element={<UsageSelectionPage />} />
          <Route path="/verify-email" element={<VerifyAccount />} />
          {/* <Route path="/teste" element={<AssetPage1 />} /> */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/auth/login" element={<LoginPage />} />
          <Route path="/users/novos-usuarios" element={<RegisterPage />} />
          <Route path="/home/dashboard" element={
            <PrivateRoute>
              <MainLayout>
                <Dashboard />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/cadastro/ativos" element={
            <PrivateRoute>
              <MainLayout>
                <AssetForm />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/asset/:id" element={
            <PrivateRoute>
              <MainLayout>
                <AssetOverview />
              </MainLayout>
            </PrivateRoute>
          } />

          <Route path="/edit-asset/:assetId" element={
            <PrivateRoute>
              <MainLayout>
                <AssetFormEdit />
              </MainLayout>
            </PrivateRoute>
          } />

          <Route path="/gerenciamento/ativos" element={
            <PrivateRoute>
              <MainLayout>
                <InventoryPage />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/gerenciamento/licencas" element={
            <PrivateRoute>
              <MainLayout>
                <InventoryLicensePage />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/criar-chamado" element={
            <PrivateRoute>
              <MainLayout>
                <ChamadosForm />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/controle/perfil" element={
            <PrivateRoute>
              <MainLayout>
                <Profile />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/general/account" element={
            <PrivateRoute>
              <MainLayout>
                <AccountDetails />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/general/inscricao" element={
            <PrivateRoute>
              <MainLayout>
                <Subscription />
              </MainLayout>
            </PrivateRoute>
          } />

          <Route path="/scan/ativo" element={
            <PrivateRoute>
              <MainLayout>
                <QrCodeScanner />
              </MainLayout>
            </PrivateRoute>
          } />
          <Route path="/network-error" element={<NetworkError />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AxiosInterceptor>
    </Router>
  );
};

export default App;
