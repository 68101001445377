import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Subscription = () => {
  const [plan, setPlan] = useState(null); // Estado para armazenar o plano do usuário
  const [loading, setLoading] = useState(true); // Estado para controle de carregamento
  const [error, setError] = useState(null); // Estado para armazenar erros da requisição

  useEffect(() => {
    // Recupera o token do localStorage
    const token = localStorage.getItem('token'); // Ajuste o nome conforme seu caso

    if (!token) {
      setError("Usuário não autenticado.");
      setLoading(false);
      return;
    }

    // Faz a requisição à API para obter o plano do usuário
    axios.get('/api/user/plan/', {
      headers: {
        Authorization: `Bearer ${token}` // Adiciona o token ao cabeçalho
      }
    })
      .then(response => {
        setPlan(response.data); // Armazena os dados do plano no estado
        setLoading(false); // Atualiza o estado para indicar que a requisição terminou
      })
      .catch(err => {
        setError("Não foi possível obter as informações do plano.");
        setLoading(false);
      });
  }, []); // O useEffect roda apenas uma vez após o componente ser montado

  // Exibe um carregando ou erro enquanto a requisição é feita
  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Função para verificar se o plano é o selecionado
  const isPlanSelected = (planName) => plan && plan.name === planName;

  return (
    <div className="p-8 bg-gray-50">
      {/* Header Section */}
      <div className="flex items-center space-x-2 text-gray-500 text-sm">
          <span className="text-blue-500">Detalhes da conta</span>
          <span>/</span>
          <span className="font-semibold text-gray-900">Inscrição</span>
        </div>

      {/* Info Message */}
      <div className="mb-4 bg-blue-100 text-blue-700 p-4 rounded-md border border-blue-200">
        <p>Você está usando <span className="font-semibold">FREE version</span> da PinnSystem.</p>
      </div>

      {/* Title */}
      <h2 className="text-2xl font-bold text-gray-900 mb-4">Inscrição</h2>
      <p className="text-gray-600 mb-8">Detalhes da sua assinatura 🚀</p>

      {/* Billing Toggle */}
      <div className="flex items-center justify-center mb-6">
        <button className="px-4 py-2 bg-blue-100 text-blue-600 rounded-l-md border border-blue-200">Anual <span className="ml-2 text-white bg-blue-500 px-2 py-1 text-xs rounded-md">Economize 54%</span></button>
        <button className="px-4 py-2 bg-gray-100 text-gray-600 rounded-r-md border border-gray-200">Mensal</button>
      </div>

      {/* Plans */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Free Plan */}
        <div className={`p-6 bg-white border rounded-lg shadow hover:shadow-lg ${isPlanSelected('Free') ? 'border-blue-500' : ''}`}>
          <div className="text-center">
            <div className="mb-2 text-blue-500">
              <svg className="w-8 h-8 mx-auto" fill="currentColor" viewBox="0 0 24 24">
                <path d="..."></path>
              </svg>
            </div>
            <h3 className="text-lg font-semibold">Gratuito</h3>
            <p className="text-sm text-gray-500 mb-4">Atual</p>
            <h4 className="text-3xl font-bold">R$0<span className="text-lg font-normal">/mo</span></h4>
            <p className="text-sm text-gray-500">Pagamento Mensal</p>
          </div>
          <p className="text-center text-gray-600 mt-4">Grátis para sempre para todos.</p>
        </div>

        {/* Plus Plan */}
        <div className={`p-6 bg-white border rounded-lg shadow hover:shadow-lg ${isPlanSelected('Magno') ? 'border-blue-500' : ''}`}>
          <div className="text-center">
            <div className="mb-2 text-blue-500">
              <svg className="w-8 h-8 mx-auto" fill="currentColor" viewBox="0 0 24 24">
                <path d="..."></path>
              </svg>
            </div>
            <h3 className="text-lg font-semibold">Magno</h3>
            <h4 className="text-3xl font-bold">R$ 49,00<span className="text-lg font-normal">/mo</span></h4>
            <p className="text-sm text-gray-500">Pagamento anual R$ 588</p>
          </div>
          <p className="text-center text-gray-600 mt-4">Para empresas que querem mudar o gerenciamento de TI.</p>
        </div>

        {/* Team Plan */}
        <div className={`p-6 bg-white border rounded-lg shadow hover:shadow-lg ${isPlanSelected('Boos') ? 'border-blue-500' : ''}`}>
          <div className="text-center">
            <div className="mb-2 text-blue-500">
              <svg className="w-8 h-8 mx-auto" fill="currentColor" viewBox="0 0 24 24">
                <path d="..."></path>
              </svg>
            </div>
            <h3 className="text-lg font-semibold">Boos</h3>
            <h4 className="text-3xl font-bold">R$99,00<span className="text-lg font-normal">/mo</span></h4>
            <p className="text-sm text-gray-500">Pagamento anual R$ 1188</p>
          </div>
          <p className="text-center text-gray-600 mt-4">Você é o nosso chefe! Tenha controle total do seu inventário.</p>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
