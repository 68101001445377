import React, { useState } from "react";
import axios from "axios";
import { HiX } from "react-icons/hi";
import { FiTrash2 } from "react-icons/fi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { saveAs } from "file-saver";
import GenericAssetIcon from './GenericAssetIcon';

const MySwal = withReactContent(Swal);

// Funções Auxiliares
const createCanvasWithBrand = (imageUrl, callback) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const qrImage = new Image();

  qrImage.crossOrigin = "Anonymous";
  qrImage.src = imageUrl;

  qrImage.onload = () => {
    // Canvas Dimensions
    canvas.width = 300;
    canvas.height = 350;

    // QR Code
    context.fillStyle = "#ffffff";
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.drawImage(qrImage, 50, 25, 200, 200);

    // Branding Text
    context.fillStyle = "#3b82f6"; // Blue-500
    context.font = "16px Arial";
    context.textAlign = "center";
    context.fillText("Powered by PinnSystem", canvas.width / 2, 300);

    callback(canvas);
  };
};

const handleDownloadWithBrand = (asset) => {
  const imageUrl = `/api/uploads/qrcodes/asset_${asset.id}.png`;
  createCanvasWithBrand(imageUrl, (canvas) => {
    canvas.toBlob((blob) => saveAs(blob, `qrcode_${asset.id}_branded.png`));
  });
};

const handlePrintWithBrand = (asset) => {
  const imageUrl = `/api/uploads/qrcodes/asset_${asset.id}.png`;
  createCanvasWithBrand(imageUrl, (canvas) => {
    const imageURLWithBrand = canvas.toDataURL("image/png");
    const printWindow = window.open();
    printWindow.document.write(
      `<img src="${imageURLWithBrand}" style="width:100%">`
    );
    printWindow.print();
  });
};

// Componente de Entrada Reutilizável
const InputField = ({ label, value, onChange, type = "text" }) => (
  <div className="flex flex-col">
    <label className="text-xs font-medium text-gray-600">{label}</label>
    <input
      type={type}
      value={value || ""}
      onChange={onChange}
      className="mt-1 p-1 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-400"
    />
  </div>
);

// Modal Principal
const AssetDetailsModal = ({
  asset,
  onClose,
  onAssetDeleted,
  onAssetUpdated,
}) => {
  const [editableAsset, setEditableAsset] = useState(asset);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleInputChange = (field, value) =>
    setEditableAsset((prev) => ({ ...prev, [field]: value }));

  const handleDeleteAsset = async () => {
    const confirm = await MySwal.fire({
      title: "Deletar ativo?",
      text: "Essa ação não pode ser desfeita.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, deletar!",
      cancelButtonText: "Cancelar",
    });

    if (confirm.isConfirmed) {
      try {
        setIsDeleting(true);
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Usuário não autenticado");

        await axios.delete(`/api/new-assets/${asset.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        MySwal.fire("Deletado!", "O ativo foi deletado.", "success");
        onAssetDeleted(asset.id);
        onClose();
      } catch (error) {
        MySwal.fire("Erro", "Não foi possível deletar o ativo.", "error");
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const handleUpdateAsset = async () => {
    const confirm = await MySwal.fire({
      title: "Salvar alterações?",
      text: "Deseja salvar as mudanças?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, salvar!",
      cancelButtonText: "Cancelar",
    });

    if (confirm.isConfirmed) {
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Usuário não autenticado");

        await axios.put(
          `/api/news-assets/${asset.id}`,
          editableAsset,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        MySwal.fire("Atualizado!", "As alterações foram salvas.", "success");
        onAssetUpdated(editableAsset);
        onClose();
      } catch (error) {
        MySwal.fire("Erro", "Não foi possível salvar as alterações.", "error");
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-50 backdrop-blur-sm">
      <div className="bg-white rounded-lg shadow-lg max-w-lg w-full p-4">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-gray-800">Detalhes do Ativo</h2>
          <HiX
            className="text-gray-400 text-xl cursor-pointer hover:text-gray-600"
            onClick={onClose}
          />
        </div>

        {/* QR Code e Imagem */}
        <div className="flex flex-col md:flex-row items-center justify-between gap-6">
          {/* Imagem */}
          <div className="flex items-center justify-center">
            {editableAsset?.image_url ? (
              <img
                src={`https://app.pinnsystem.com/api/${editableAsset.image_url}`}
                alt={editableAsset?.name || "Ativo"}
                className="w-40 max-h-40 object-contain rounded-lg border border-gray-300 shadow-sm"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.style.display = "none"; // Oculta a imagem falha
                }}
              />
            ) : (
              <div className="w-40 h-40 flex items-center justify-center bg-gray-100 border border-gray-300 rounded-lg shadow-sm">
                <GenericAssetIcon className="w-16 h-16 text-gray-400" />
              </div>
            )}
          </div>

          {/* QR Code */}
          <div className="flex flex-col items-center">
            <img
              src={`https://app.pinnsystem.com/api/uploads/qrcodes/asset_${asset.id}.png`}
              alt={`QR Code para o ativo ${asset.name}`}
              className="w-30 h-24 bg-white p-2 rounded-md shadow-md border border-gray-300"
            />
            <p className="mt-2 text-xs text-gray-500">
              Powered by{" "}
              <span className="font-semibold text-blue-500">PinnSystem</span>
            </p>
            <div className="mt-3 flex space-x-1">
              <button
                onClick={() => handleDownloadWithBrand(asset)}
                className="bg-blue-500 text-white px-2 py-0.5 text-xs rounded hover:bg-blue-600 transition"
              >
                Download
              </button>
              <button
                onClick={() => handlePrintWithBrand(asset)}
                className="bg-blue-500 text-white px-2 py-0.5 text-xs rounded hover:bg-blue-600 transition"
              >
                Imprimir
              </button>
            </div>
          </div>
        </div>


        {/* Campos de Edição */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mt-4"> {/* Adicionando mt-4 para margem superior */}
          {[
            { label: "Nome do Ativo", field: "name" },
            { label: "Fabricante", field: "manufacturer" },
            { label: "Número de Série", field: "serial_number" },
            { label: "Categoria", field: "category" },
            { label: "Localização", field: "location" },
            { label: "Descrição", field: "technical_description" },
            { label: "Responsável", field: "responsible" },
            { label: "Valor", field: "value" },
            { label: "Status", field: "status" },
            { label: "Observações", field: "notes" },
          ].map(({ label, field }) => (
            <InputField
              key={field}
              label={label}
              value={editableAsset[field]}
              onChange={(e) => handleInputChange(field, e.target.value)}
            />
          ))}
        </div>

        {/* Botões */}
        <div className="mt-6 flex justify-between">
          <button
            onClick={handleDeleteAsset}
            disabled={isDeleting}
            className="flex items-center text-red-500 hover:text-red-600 text-sm"
          >
            <FiTrash2 className="mr-1" />
            Deletar Ativo
          </button>
          <button
            onClick={handleUpdateAsset}
            className="px-3 py-1 bg-blue-500 text-white text-sm rounded-md hover:bg-blue-600"
          >
            Salvar Alterações
          </button>
        </div>
      </div>
    </div>

  );
};

export default AssetDetailsModal;
