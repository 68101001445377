import React from 'react';
import { AlertCircle } from 'lucide-react';

const PageNotFound = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center px-6 py-12">
      <div className="max-w-md w-full text-center space-y-6">
        {/* Ícone de alerta */}
        <div className="flex justify-center">
          <AlertCircle className="h-24 w-24 text-red-500" />
        </div>
        
        {/* Número 404 */}
        <h1 className="text-9xl font-bold text-gray-900">404</h1>
        
        {/* Título */}
        <h2 className="text-3xl font-semibold text-gray-800">
          Página Não Encontrada
        </h2>
        
        {/* Mensagem */}
        <p className="text-gray-600 text-lg">
          Desculpe, a página que você está procurando não existe ou foi movida.
        </p>
        
        {/* Botão para voltar */}
        <div className="mt-8">
          <a 
            href="/"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-300"
          >
            Voltar para o Início
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;