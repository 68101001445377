import React, { useState } from 'react';

function DashboardHeader() {
    return (
        <div className="flex items-center space-x-3 mb-4">
            <h1 className="text-2xl font-semibold text-gray-800">Olá, bem-vindo de volta</h1>
            <span role="img" aria-label="wave" className="text-2xl">👋</span>
        </div>
    );
}

function Tabs() {
    const [activeTab, setActiveTab] = useState('Overview');

    return (
        <div className="flex space-x-4 bg-gray-100 p-2 rounded-lg">
            <button
                className={`px-4 py-2 text-sm font-medium rounded-lg ${
                    activeTab === 'Overview' ? 'bg-white text-black shadow' : 'text-gray-500'
                }`}
                onClick={() => setActiveTab('Overview')}
            >
                Overview
            </button>
            <button
                className={`px-4 py-2 text-sm font-medium rounded-lg ${
                    activeTab === 'Analytics' ? 'bg-white text-black shadow' : 'text-gray-500'
                }`}
                onClick={() => setActiveTab('Analytics')}
            >
                Analytics
            </button>
        </div>
    );
}

export default function Dashboard() {
    return (
        <div className="p-6">
            <DashboardHeader />
            <Tabs />
        </div>
    );
}
